import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import { useIsVisualEditorPreview } from '../../../../hooks/useIsVisualEditorPreview';
import SiteLayout from '../../../layouts/SiteLayout/SiteLayout';
import SiteSection from '../../../layouts/SiteLayout/SiteSection/SiteSection';

const Prompt = loadable(() =>
  import(/* webpackChunkName: "Prompt" */ '../../../sections/Prompt/Prompt'),
);

const HomeHeroBanner = loadable(() =>
  import(
    /* webpackChunkName: "HomeHeroBanner" */ '../../../styled/siteBuilderModules/HeroBannerModule'
  ),
);

export function HomeView(props) {
  const {
    siteContent: { data: { sections = [] } = {} } = {},
    siteHeader: { data: { homeHeaderStyle, homeHeroBanner } = {} } = {},
    options: { data: { brand: { whiteLogo, logoSize } = {} } = {} } = {},
  } = props;

  const isVisualEditor = useIsVisualEditorPreview();

  return (
    <SiteLayout>
      {homeHeaderStyle === 'TRANSPARENT_WITHOUT_HEADER' && !isVisualEditor && (
        <HomeHeroBanner
          section={homeHeroBanner}
          brandLogo={whiteLogo?.path}
          brandLogoSize={logoSize}
        />
      )}
      <Prompt>
        {sections.map((section, index) => (
          <SiteSection key={section.id} section={section} index={index} />
        ))}
      </Prompt>
    </SiteLayout>
  );
}

HomeView.propTypes = {
  siteContent: PropTypes.shape({
    data: PropTypes.shape({
      sections: PropTypes.arrayOf(PropTypes.object),
      valueProposition: PropTypes.string,
      subTitle: PropTypes.string,
      text: PropTypes.string,
    }),
    meta: PropTypes.shape({
      description: PropTypes.string,
    }),
  }),
};

function mapStateToProps(state) {
  return {
    siteContent: state.siteContent,
    siteHeader: state.siteHeader,
    options: state.options,
  };
}

export default connect(mapStateToProps)(HomeView);
